import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlButtonComponent } from './fl-button/fl-button.component';
import { DirectivesModule } from '../directives/directives.module';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TypeofPipe } from '../pipes/typeof/typeof.pipe';



@NgModule({
  providers:[TypeofPipe],
  imports: [
    FormsModule,
    IonicModule,
    CommonModule,
    DirectivesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  declarations: [
    LanguagePickerComponent,
    FlButtonComponent,
    TypeofPipe
  ],
  exports: [
    LanguagePickerComponent,
    FlButtonComponent,
  ]
})
export class ComponentModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
