import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { ISOCountry } from '../../../country-list';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    SanitizeHtmlPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    HttpClientModule
  ],
  exports: [
    SanitizeHtmlPipe,
  ],
})

export class CommonNetexpoModule {
  constructor(
    private translate: TranslateService
  ) { }

  getEventNameFromProgram(prog: string): string {
    const splitted = prog.replace(/^\(.*?\)\s*/, '').trim().split('-');
    let toDisplay = prog;
    if (splitted.length > 9) {
      const evt = splitted.slice(4, -4).join('-').replace(/[-_]/g,' ');
      toDisplay = evt.charAt(0).toUpperCase() + evt.slice(1);
    } else  if (splitted.length == 9 || splitted.length == 8) {
      const evt = (splitted.length == 8 ? splitted[3] : splitted[4]).replace(/_/g,' ');
      toDisplay = evt.charAt(0).toUpperCase() + evt.slice(1);
    }
    return toDisplay;
  }

  delay(milliseconds: number): Promise<void> {
    console.log(" PAUSE (" + milliseconds / 1000 + "s) ...");
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  async syncLang(lang: string) {
    await this.translate.use(lang).toPromise()
  }
  isEmail(email: string) {
    //  initial :
    //  return /^[\w\+\.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(email);
    //  nouveau regex incomplet pas de gestion +  
    //  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(email);
    //  regex noveau incluant .%+- comme autorisé dans l'adresse (cas toto+titi@gmail.com)
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email);
  }

  isPhone(phone: string) {
    return /^[0-9+\ \.\-\(\)]+$/.test(phone);
  }

  async ago(date: any) {
    if(typeof date == 'string'){
      date= new Date(date)
    }
    
    const now = new Date();
    const yesterday = new Date(now);
    const hourToday = now.getHours();
    yesterday.setHours(0, 0, 0, 0);
    const diffMs = now.getTime() - date;
    
    const diffMins = Math.floor(diffMs / (1000 * 60));
    const diffHrs = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHrs / 24);

    let res = '';
    if (diffMins <= 1) {
      res = await this.translate.get('now').toPromise()
    } else if (diffMins < 60) {
      res = await this.translate.get('min-ago', { mins: diffMins }).toPromise()
    } else if (diffHrs < hourToday) {
      res = await this.translate.get('h ago', { hours: diffHrs }).toPromise()
    } else if (diffDays <= 1) {
      res = await this.translate.get('yesterday').toPromise()
    } else if (diffDays < 7) {
      res = await this.translate.get('days-ago').toPromise();
      //          new Date(date).getDay(); 0 sunday .. 6 saturday
    } else if(diffDays<30){
      res = await this.translate.get('month-ago',{n: 1 }).toPromise()
    }else if(diffDays<364){
      const months = Math.floor(diffDays/30)
      res = await this.translate.get('months-ago',{n: months }).toPromise()
    } else if(diffDays<700){
      res = await this.translate.get('year-ago',{n : 1}).toPromise()
    }else {
      const years = Math.floor(diffDays/365)
      res = await this.translate.get('years-ago',{n : years}).toPromise()
    }
    return res;
  }

  findCountryNameByCode(code: string): string | undefined {
    const country = ISOCountry.find(item => item[code]);
    return country ? country[code] : undefined;
  }

  findCountryCodeByName(name: string): string | undefined {
    const country = ISOCountry.find(item => Object.values(item)[0] === name);
    return country ? Object.keys(country)[0] : undefined;
  }

  optionsToken() {
    const token = localStorage.getItem('token');
    return (token ? { headers: { Authorization: `Bearer ${token}` } } : {});
  }

  async getCompanyName() {
    const url = environment.API_BASE_PATH + "/api/company/user";
    const response = await fetch(url,this.optionsToken());
    const data = await response.json();
    return data;
  }


}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}